import React, { useState, useEffect } from "react";
import bannercall from "./assets/images/call-icon.gif";
import bnrmainimg from "./assets/images/banner-image-.png";
import videoicon from "./assets/images/play-button.webp";
import ReactPlayer from "react-player";
import crossImage from "./assets/images/cross.png";

const Banner = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const handleModalClose = () => setIsModalOpen(false);

        // Add event listener for modal close
        const modalElement = document.getElementById("exampleModalToggle");
        modalElement?.addEventListener("hidden.bs.modal", handleModalClose);

        return () => {
            // Clean up the event listener
            modalElement?.removeEventListener("hidden.bs.modal", handleModalClose);
        };
    }, []);

    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    return (
        <div className="sn-banner-hero">
            {/* sn banner start */}
            <section className="sn-banner-main">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-5 col-sm-12">
                            <div className="sn-tagline-platform">
                                <small>
                                    <img src={bannercall} alt="Footer Logo" />
                                    No. 1 Platform for Call Management
                                </small>
                                <h1>The Future of Sales is Here: AI-powered CRM for Exponential Growth</h1>
                                <p>
                                    Seamlessly monitor calls in real-time, track key agent performance metrics, and gain
                                    insights that can help you optimize your workflow and boost your team's productivity
                                    with our all-in-one call management solution.
                                </p>
                                <div className="bs-login">
                                    <a href="https://app.salesninjacrm.com/register" target="_blank" className="ps-btn">
                                        Free trial
                                    </a>
                                </div>
                                <div className="sn-take-product-bnr">
                                    <a
                                        href="javascript:;"
                                        data-bs-target="#exampleModalToggle"
                                        data-bs-toggle="modal"
                                        onClick={handleModalOpen}
                                    >
                                        <img src={videoicon} alt="Banner img" /> Take Product Tour
                                    </a>
                                </div>
                                {/* Modal video */}
                                <div
                                    className="modal fade sn-webvideproduct"
                                    id="exampleModalToggle"
                                    aria-hidden="true"
                                    aria-labelledby="exampleModalToggleLabel"
                                    tabIndex="-1"
                                >
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header border-0 sn-header-video-section pb-0">
                                                <h5 className="modal-title fs-6" id="exampleModalToggleLabel">
                                                    SalesNinja - No. 1 Platform for Call Management
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close close-btn-video-bnr"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <img src={crossImage} alt="close" />
                                                </button>
                                            </div>
                                            <div className="modal-body sn-video-boby-web">
                                                <div style={{ position: "relative", paddingTop: "56.25%" }}>
                                                    <ReactPlayer
                                                        url="https://app.salesninjacrm.com/static/media/salesninja.mp4"
                                                        controls
                                                        width="100%"
                                                        height="100%"
                                                        style={{ position: "absolute", top: 0, left: 0 }}
                                                        playing={isModalOpen} // Play video only when modal is open
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Modal */}
                            </div>
                        </div>
                        <div className="col-md-7 col-sm-12">
                            <div className="sn-banner-img-main">
                                <img src={bnrmainimg} alt="Banner img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end banner */}
        </div>
    );
};

export default Banner;
