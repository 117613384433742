import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import downbutton from "./assets/images/down-button.png";
import exampleImage from "./assets/images/logo-main-h.png";
import india from "./assets/images/India.svg"
import dubai from "./assets/images/dubaiflag.png"

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    return (
        <div className="sn-footer-main">
            <footer>
                <section className="sn-footer-main">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                <div className="sn-footer-logo">
                                    <Link to="/">
                                        <img src={exampleImage} alt="Logo" />
                                    </Link>
                                    <p>
                                        Follow us for actionable tips, expert insights, and the latest industry trends.
                                    </p>
                                    <ul className="sn-social-icons">
                                        <li>
                                            <a href="https://www.facebook.com/redvisionfuturetech" target="_blank">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-facebook"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/redvisionfuturetech/" target="_blank">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-instagram"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://x.com/redvisionft" target="_blank">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-twitter-x"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                                </svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/redvisionfuturetech/"
                                                target="_blank"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    className="bi bi-linkedin"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                                                </svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2">
                                <div className="sn-footer-menu">
                                    <h3>Quick Links</h3>
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <a href="/#about">About Us</a>
                                        </li>
                                        <li>
                                            <a href="/#Features">Features</a>
                                        </li>
                                        <li>
                                            <a href="/#Pricing">Pricing</a>
                                        </li>
                                        <li>
                                            <a href="/#Contact">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3">
                                <div className="sn-footer-menu">
                                    <h3>Useful Links</h3>
                                    <ul>
                                        <li>
                                            <a href="privacy-policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="terms-conditions">Terms & Conditions</a>
                                        </li>
                                        <li>
                                            <a href="refund-policy-cancellation-policy">Refund & Cancellation Policy</a>
                                        </li>
                                        {/* Additional blog links can be added here */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                <div className="sn-footer-menu">
                                    <h3>Get In Touch</h3>
                                    <div className="ph-footer-content">
                                        
                                        <div className="ph-content-f">
                                            <p className="ph-contact-footer">
                                                <img src={india} alt="India" className="sn-flat-img" />
                                            </p>
                                            <p className="mb-0">
                                                <a href="javascript:;">
                                                101, 45 Hare Krishna Vihar, Behind Advanced Academy, Nipania, Indore, 452010 (MP)
                                                </a>
                                            </p>
                                        </div>
                                        <div className="ph-content-f sn-web-country-contact">
                                            <p className="ph-contact-footer">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="13"
                                                    height="13"
                                                    fill="currentColor"
                                                    class="bi bi-telephone-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                                                    />
                                                </svg>
                                            </p>
                                            <p>
                                                <a href="tel:971545903053">+91 929 456 6690</a>
                                            </p>
                                        </div>
                                        

                                        <div className="ph-content-f">
                                            <p className="ph-contact-footer">
                                            <img src={dubai} alt="Dubai" className="sn-flat-img" />
                                            </p>
                                            <p className="mb-0">
                                                <a href="javascript:;">
                                                    Aspin Commercial Tower – Sheikh Zayed Road, 5th Floor – Dubai
                                                </a>
                                            </p>
                                        </div>

                                        <div className="ph-content-f sn-web-country-contact">
                                            <p className="ph-contact-footer">
                                            <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="13"
                                                    height="13"
                                                    fill="currentColor"
                                                    class="bi bi-telephone-fill"
                                                    viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                                                    />
                                                </svg>   
                                            </p>
                                            <p>
                                                <a href="tel:971545903053">+97 154 590 3053</a>
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="sn-footer-copyright p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                                <div className="sn-footer-copy-txt">
                                    <small>
                                        © Copyright 2025 REDVision FutureTech IT Services Co, Inc. All rights reserved.
                                        Various trademarks held by their respective owners.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>

            <div className="scroll-to-top">
                {isVisible && (
                    <button id="scrollToTopBtn" className="sn-shap-elements" onClick={scrollToTop}>
                        <img src={downbutton} alt="Scroll to Top" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Footer;
